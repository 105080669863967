<template>
    <v-col md="6"
           class="px-12"
           align-self="center"
    >
        <v-row align="center"
               justify="center"
        >
            <v-img src="/images/logo-quadia-horizontal-united-w300.svg"
                   max-width="300"
                   max-height="200"
                   class="logo"
            />
        </v-row>

        <v-form class="mt-10">
            <v-text-field
                v-model="email"
                v-validate="'required|email'"
                label="Email"
                :error-messages="errors.collect('email')"
                data-vv-name="email"
                prepend-icon="mdi-account"
                required
            />
            <div class="text-center">
                <v-btn color="accent"
                       :loading="busy"
                       :disabled="busy"
                       class="mt-2 reset-link-button"
                       @click="submit"
                >
                    Send password reset link
                </v-btn>
            </div>
            <div class="text-center">
                <v-btn text
                       class="my-4"
                       color="primary"
                       @click="$router.back()"
                >
                    Back
                </v-btn>
            </div>
        </v-form>
    </v-col>
</template>


<script>
    import { $http, makeUrl } from '../../services/http';
    import {toastSuccess, toastError} from '../../services/responseErrors';

    export default {
        data() {
            return {
                email: this.$route.query.email || '',
                busy: false
            };
        },
        methods: {
            reset() {
                this.errors.clear();
                $http
                    .post(makeUrl('sso/password/request'), {
                        email: this.email
                    })
                    .then(() => {
                        toastSuccess('Password reset sent, you\'ll receive a mail shortly.');
                    })
                    .catch(error => {
                        if (error.response && error.response.status == 400) {
                            toastError('The given email does not belong to an existing user');
                        }
                    })
                    .finally(() => {
                        this.busy = false;
                    });
            },
            submit() {
                this.$validator.validate().then(valid => {
                    if (valid) {
                        this.busy = true;
                        this.reset();
                    }
                });
            }
        }
    };
</script>
<style lang="scss" scoped>
.reset-link-button{
    font-weight: 600 !important;
}
</style>
