import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VeeValidate from 'vee-validate';
import './_scss/main.scss';
import vuetify from './plugins/vuetify';
import Toasted from 'vue-toasted';

Vue.config.productionTip = true;
Vue.use(VeeValidate);
Vue.use(Toasted, {
  position: 'bottom-center',
  keepOnHover: true,
  duration: 5000,
  className: 'v-alert v-sheet',
  closeOnSwipe: true,
});

new Vue({
  router,
  vuetify,
  Toasted,
  render: h => h(App)
}).$mount('#app');
