<template>
    <v-col md="6"
           class="px-12 mb-8 login"
           align-self="center"
    >
        <v-row align="center"
               justify="center"
        >
            <v-img src="/images/logo-quadia-horizontal-united-w300.svg"
                   class="mb-10 mt-10 logo"
                   max-width="300"
                   max-height="200"
            />
        </v-row>
        <v-form v-if="showForm">
            <v-text-field
                v-model="username"
                v-validate="'required|email'"
                label="Username"
                :error-messages="errors.collect('email')"
                data-vv-name="email"
                prepend-icon="mdi-account"
                required
                @keypress.enter="submit"
            />
            <v-text-field
                ref="passwordField"
                v-model="password"
                v-validate="'required|min:6'"
                label="Password"
                data-vv-name="password"
                :error-messages="errors.collect('password')"
                prepend-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                @keydown="checkCapsLock"
                @keypress.enter="submit"
            >
                <template v-if="hasCapsLock && passwordIsFocused && !hasMsCapsLockWarning"
                          v-slot:append-outer
                >
                    <small class="error">Caps Lock</small>
                </template>
            </v-text-field>

            <div class="text-center mb-4">
                <v-btn color="accent"
                       class="login-button"
                       @click="submit"
                >
                    Login
                </v-btn>
            </div>
            <div class="text-center">
                <div class="my-2">
                    <router-link :to="{ name: 'passwordReset' }"
                                 tag="a"
                    >
                        Forgot password?
                    </router-link>
                </div>
            </div>
        </v-form>
        <v-row v-if="showProducts"
               align="center"
               justify="space-around"
        >
            <h5 class="text-center mb-4">
                Which product are you willing to use?
            </h5>
            <v-row>
                <v-col v-for="product in products"
                       :key="product.url"
                       class="text-center"
                       cols="12"
                       xl="6"
                       lg="6"
                       md="6"
                       sm="6"
                >
                    <v-btn color="accent"
                           class="route-button"
                           @click="goToUrl(product.url)"
                    >
                        {{ product.name }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-row>
    </v-col>
</template>

<script>
    import { $http, makeUrl } from '../../services/http';

    export default {
        data() {
            return {
                showPassword: false,
                username: '',
                password: '',
                hasCapsLock: false,
                products: null
            };
        },
        computed: {
            passwordIsFocused() {
                return this.$refs.passwordField.isFocused;
            },
            hasMsCapsLockWarning() {
                // IE 10 and 11 caps lock warning proprietary support check
                // Caps lock detection in IE is fishy, but since it has a caps lock warning anyway,
                // we degrade to it
                return typeof document.msCapsLockWarningOff !== 'undefined';
            },
            showForm() {
                return this.products === null;
            },
            showProducts() {
                return this.products !== null;
            }
        },
        methods: {
            checkCapsLock(event) {
                if (typeof event.getModifierState === 'function') {
                    const capsLockState = event.getModifierState('CapsLock');
                    this.hasCapsLock = event.keyCode === 20 ? !capsLockState : capsLockState;
                }
            },
            login() {
                this.errors.clear();
                $http.post(makeUrl('sso/login'), {
                    username: this.username,
                    password: this.password
                }, {
                    withCredentials: true
                }).then(response => {
                    if(this.$route.query.path) {
                        return this.goToUrl(this.$route.query.path);
                    }

                    if(response.data.products.length === 1) {
                        return this.goToUrl(response.data.products[0].url);
                    }

                    this.products = response.data.products;
                }).catch(error => {
                    if (error.response && error.response.status == 422) {
                        if (error.response.data.username) {
                            this.errors.add({
                                field: 'email',
                                msg: error.response.data.username.join('<br>')
                            });
                        }
                        if (error.response.data.password) {
                            this.errors.add({
                                field: 'password',
                                msg: error.response.data.password.join('<br>')
                            });
                        }
                    }
                    if (error.response && error.response.status == 401) {
                        this.errors.add({
                            field: 'auth',
                            msg: error.response.data.error
                        });
                    }
                });
            },
            submit() {
                this.$validator.validate().then(valid => {
                    if (valid) {
                        this.login();
                    }
                });
            },
            goToUrl(url) {
                window.location.href = url;
            }
        },
        mounted() {
            document.onkeydown = this.checkCapsLock;
            this.$validator.localize('en', {
                messages: {
                    email: 'The email field must contain a valid email address.'
                },
            });
        }
    };
</script>
<style lang="scss" scoped >

h5{
    font-size: 18px !important
}

.login-button{
    font-weight: 600 !important;
}

.route-button{
    width: 100%;
    font-weight: 600 !important;
}

</style>

<style lang="scss">
.logo{
    .v-image__image--cover{
        background-size: initial;
    }
}
</style>