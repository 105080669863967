import axios from 'axios';
import router from '../router';
import {
    handleAuthenticationRequired, handleNotFound,
    handleServerErrors, handleTooManyAttempts, handleTooManyLoginAttempts,
    handleUnauthorized,
    handleUnprocessable
} from './responseErrors';

export const $http = axios.create();

export const makeUrl = function (path) {
    return process.env.VUE_APP_API_HOST + path;
};

$http.interceptors.response.use((response) => {
    // Do something with response data
    return response;
}, (error) => {
    if (typeof(error.response) === 'undefined') {
        return;
    }
    // Do something with response error
    switch (error.response.status) {
        default:
        case 500:
            handleServerErrors(error);
            break;
        case 400:
            break;
        case 401:
            handleAuthenticationRequired(error);
            break;
        case 403:
            handleUnauthorized(error);
            break;
        case 404:
            handleNotFound(error);
            break;
        case 410:
            break;
        case 422:
            handleUnprocessable(error);
            break;
        case 429:
            if (router.history.current.name === 'login') {
                handleTooManyLoginAttempts(error);
            } else {
                handleTooManyAttempts(error);
            }
            break;
    }

    return Promise.reject(error);
});
