<template>
    <v-container class="grey lighten-2 container--fluid"
                 fill-height
    >
        <v-row no-gutters
               align-content="center"
               justify="center"
               style="max-width: 960px; margin-left: auto; margin-right: auto"
        >
            <v-col class="elevation-12 white darken-1">
                <v-row no-gutters
                       align-content="center"
                       justify="center"
                >
                    <router-view />

                    <v-col xl="6"
                           class="pa-12 white--text accent info-container"
                    >
                        <h2 class="my-4 mt-12">
                            Services
                        </h2>

                        <div class="my-2 list-dot">
                            <v-icon class="white--text mr-3">
                                check_circle_outline
                            </v-icon>
                            <span>Upload, manage, publish and analyse your videos</span>
                        </div>
                        <div class="my-2 list-dot">
                            <v-icon class="white--text mr-3">
                                check_circle_outline
                            </v-icon>
                            <span>AVG & ISO 27001 proof</span>
                        </div>

                        <h2 class="mt-12 mb-0">
                            Want to know more
                        </h2>
                        <p class="mt-6">
                            About our video platform or other services? <a style="color: white"
                                                                           href="mailto:info@motionmakers.nl"
                            >Contact us!</a>
                        </p>
                        <p>info@motionmakers.nl | +31 (0) 35 77 33 105</p>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {};
</script>

<style scoped>
    h2 {
        opacity: .5
    }

    .list-dot i.v-icon {
        opacity: .5;
    }

    .info-container {
        height: 600px;
    }

    .info-container:before {
        content: "";
        display: block;
        margin-top: 100px;
    }
</style>
