import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import AuthLayout from '../components/layouts/auth';
//pages
import Login from '../components/pages/login';
import ResetPassword from '../components/pages/resetPassword';
import ResetPasswordToken from '../components/pages/resetPasswordToken';
import ActivateInvite from '../components/pages/activate-invite';

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/', component: AuthLayout, children: [
                {
                    path: '/login',
                    name: 'login',
                    component: Login
                },
                {
                    path: '/password/reset',
                    name: 'passwordReset',
                    component: ResetPassword
                },
                {
                    path: '/password/reset/:token/:email',
                    name: 'passwordResetToken',
                    component: ResetPasswordToken,
                    props: true
                },
                {
                    path: '/activate/:token',
                    name: 'activateInvite',
                    component: ActivateInvite
                }
            ]
        }
    ]
});

export default router;
