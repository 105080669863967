<template>
    <v-col md="6"
           class="px-12"
           align-self="center"
    >
        <v-row align="center"
               justify="center"
        >
            <v-img
                src="/images/logo-quadia-horizontal-united-w300.svg"
                class="mb-10 mt-10 logo"
                max-width="300"
                max-height="200"
            />
        </v-row>
        <v-row v-if="loading"
               align="center"
               justify="center"
        >
            <v-progress-circular indeterminate />
        </v-row>
        <v-row align="center"
               justify="center"
        >
            <v-form v-if="!activated">
                <div>Please specify your password to activate your account below:</div>

                <v-text-field
                    ref="password"
                    v-model="user.password"
                    v-validate="'required|min:10'"
                    label="Password"
                    data-vv-name="password"
                    :error-messages="errors.collect('password')"
                    prepend-icon="mdi-lock"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    @keydown="checkCapsLock"
                >
                    <template v-if="showCapsLockForPassword"
                              v-slot:append-outer
                    >
                        <small class="error">Caps Lock</small>
                    </template>
                </v-text-field>
                <v-text-field
                    ref="passwordConfirmation"
                    v-model="user.password_confirmation"
                    v-validate="'required|confirmed:password'"
                    label="Confirm password"
                    prepend-icon="mdi-lock"
                    :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showConfirmPassword ? 'text' : 'password'"
                    data-vv-name="password_confirmation"
                    data-vv-as="password"
                    :error-messages="errors.collect('password_confirmation')"
                    @paste="(e) => { e.preventDefault() }"
                    @click:append="showConfirmPassword = !showConfirmPassword"
                    @keydown="checkCapsLock"
                >
                    <template v-if="showCapsLockForPasswordConfirmation"
                              v-slot:append-outer
                    >
                        <small class="error">Caps Lock</small>
                    </template>
                </v-text-field>
                <div class="text-center">
                    <v-btn color="accent"
                           @click="submit"
                    >
                        Activate my account
                    </v-btn>
                </div>
            </v-form>
        </v-row>
        <v-row v-if="activated"
               align="center"
               justify="center"
        >
            <p class="text-center">
                Your account is now activated. Thank You.
            </p>
        </v-row>
        <v-row v-if="activated"
               align="center"
               justify="center"
        >
            <v-btn color="accent"
                   @click="login"
            >
                Sign In
            </v-btn>
        </v-row>
    </v-col>
</template>

<script>
    import { $http, makeUrl } from '../../services/http';

    export default {
        name: 'ActivateInvite',
        data() {
            return {
                user: {
                    password: '',
                    password_confirmation: ''
                },
                activated: false,
                loading: false,
                showPassword: false,
                showConfirmPassword: false,
                hasCapsLock: false
            };
        },
        computed: {
            showCapsLockForPassword() {
                return this.hasCapsLock && this.$refs.password.isFocused;
            },
            showCapsLockForPasswordConfirmation() {
                return this.hasCapsLock && this.$refs.passwordConfirmation.isFocused;
            }
        },
        methods: {
            checkCapsLock(event) {
                if (typeof document.msCapsLockWarningOff !== 'undefined') {
                    // IE 10 and 11 caps lock warning proprietary support check
                    // Caps lock detection in IE is fishy, but since it has a caps lock warning anyway,
                    // we degrade to it
                    return;
                }

                const capsLockState = event.getModifierState('CapsLock');
                this.hasCapsLock = event.which === 20 ? !capsLockState : capsLockState;
            },
            submit() {
                this.$validator.validate().then(valid => {
                    if (valid) {
                        this.loading = true;
                        var url = makeUrl('sso/activation-token/' + this.$route.params.token);
                        $http
                            .post(url, this.user)
                            .then(() => {
                                this.loading = false;
                                this.activated = true;
                            })
                            .catch(() => {
                                this.loading = false;
                            });
                    }
                });
            },
            login() {
                window.location.href = this.$route.query.path || '/login';
            }
        },
        mounted() {
            document.onkeydown = this.checkCapsLock;
        }
    };
</script>

<style lang="scss">
</style>
