<template>
    <v-col md="6"
           class="px-12"
           align-self="center"
    >
        <v-row align="center"
               justify="center"
        >
            <v-img
                src="/images/logo-quadia-horizontal-united-w300.svg"
                max-width="300"
                max-height="200"
                class="logo"
            />
        </v-row>
        <v-form class="mt-10">
            <v-text-field
                v-model="emailModel"
                v-validate="'required|email'"
                label="Email"
                :error-messages="errors.collect('email')"
                data-vv-name="email"
                prepend-icon="mdi-account"
                required
            />
            <v-text-field
                ref="password"
                v-model="password"
                v-validate="'required|min:10'"
                label="Password"
                data-vv-name="password"
                :error-messages="errors.collect('password')"
                prepend-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
            />
            <v-text-field
                v-model="passwordConfirmation"
                v-validate="'required|confirmed:password'"
                label="Confirm password"
                prepend-icon="mdi-lock"
                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showConfirmPassword ? 'text' : 'password'"
                data-vv-name="password_confirmation"
                data-vv-as="password"
                :error-messages="errors.collect('password_confirmation')"
                @paste="onPaste"
                @click:append="showConfirmPassword = !showConfirmPassword"
            />
            <div class="text-center">
                <v-btn
                    color="accent"
                    :loading="busy"
                    :disabled="busy"
                    class="mt-2 reset-password-button"
                    @click="submit"
                >
                    Reset password
                </v-btn>
            </div>
            <div class="text-center">
                <v-btn text
                       class="my-4"
                       color="accent"
                       @click="$router.back()"
                >
                    Back
                </v-btn>
            </div>
            <v-snackbar v-model="snackbar"
                        :timeout="10000"
            >
                <span v-if="responseError === 'INVALID_EMAIL'">
                    The given email does not belong to an existing user
                </span>
                <span v-if="responseError === 'EXPIRED_TOKEN'">
                    Your password reset link has expired, for password reset use this
                    link:
                    <router-link
                        :to="{ name: 'passwordReset', query: { email } }"
                        tag="a"
                    >
                        reset
                    </router-link>
                </span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="blue"
                           text
                           v-bind="attrs"
                           @click="closeSnackbar"
                    >
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
        </v-form>
    </v-col>
</template>

<script>
    import { $http, makeUrl } from '../../services/http';
    import { toastSuccess } from '../../services/responseErrors';

    export default {
        props: {
            token: {
                type: String,
                default: ''
            },
            email: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                password: '',
                passwordConfirmation: '',
                emailModel: this.email,
                showPassword: false,
                showConfirmPassword: false,
                busy: false,
                snackbar: false,
                responseError: null,
            };
        },
        methods: {
            onPaste(e) {
                e.preventDefault();
            },
            reset() {
                $http
                    .post(makeUrl('sso/password/reset'), {
                        token: this.token,
                        email: this.emailModel,
                        password: this.password,
                        password_confirmation: this.passwordConfirmation,
                    })
                    .then(() => {
                        toastSuccess('Your password has been reset successfully');
                        this.$router.push({ name: 'login' });
                    })
                    .catch((error) => {
                        if (error.response && error.response.status == 410) {
                            this.snackbar = true;
                            this.responseError = error.response.data.error;
                        }
                    })
                    .finally(() => {
                        this.busy = false;
                    });
            },
            submit() {
                this.$validator.validate().then((valid) => {
                    if (valid) {
                        this.busy = true;
                        this.reset();
                    }
                });
            },
            closeSnackbar() {
                this.snackbar = false;
                this.responseError = null;
            }
        },
    };
</script>
<style lang="scss" scoped>
.reset-password-button {
  font-weight: 600 !important;
}
</style>
